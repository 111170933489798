import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "../matches/style.css";
import { Logout } from "../../../state/features/loginSlice";
import { auth } from "../../../services/firebase";
import { useChatStore } from "../../../state/firebase/chatFirehoseStore";
import Header from "../../header/HeaderWithLogin";
import Footer from "../../Footer";
import { resetPackage } from "../../../state/features/packageSlice";
import { resetAuth } from "../../../state/features/authSlice";
import { resetFirebase } from "../../../state/features/firebaseSlice";

const ProfileTabBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("");
  const { resetChat } = useChatStore();

  function logoutHandler() {
    auth.signOut();
    resetChat();
    dispatch(Logout());
    dispatch(resetPackage());
    dispatch(resetAuth());
    dispatch(resetFirebase());
  }

  useEffect(() => {
    const path = location.pathname.split("/")[2] ?? "matches";
    setTabValue(path);
  }, []);

  useEffect(() => {
    if (location.pathname === "/profile" || location.pathname === "/profile/") {
      const path =
        location.pathname.split("/")[2] &&
        location.pathname.split("/")[2].trim().length > 0
          ? location.pathname.split("/")[2]
          : "matches";
      setTabValue(path);
      navigate(`/profile/${path}`, { replace: true });
    }
  }, []);

  const matchesNavigator = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    path: string,
  ) => {
    e.preventDefault();
    setTabValue(path);
    navigate(`/profile/${path}`, { replace: false });
  };

  const hideTabBar = () => {
    const settingsCompArr = [
      "blocked-profiles",
      "change-password",
      "hide-delete-profile",
      "numerology-match",
      "numerology-details",
      "kundli-match",
      "kundli-details",
      "payment",
      "payment-success",
    ];
    return settingsCompArr.some((sub) => window.location.href.includes(sub));
  };

  const hideHeader = () => {
    const settingsCompArr = [
      "change-password",
      "numerology-details",
      "numerology-match",
      "numerology-details",
      "kundli-match",
      "kundli-details",
      "payment-success",
    ];
    return settingsCompArr.some((sub) => window.location.href.includes(sub));
  };

  const hideFooter = () => {
    const settingsCompArr = ["chat", "payment-success", "change-password"];
    return settingsCompArr.some((sub) => window.location.href.includes(sub));
  };

  return (
    <div className="MatchesPage">
      {!hideHeader() && <Header />}
      {!hideTabBar() ? (
        <>
          <div className="container matches-content">
            <div className="row">
              <div className="col-md-10">
                <div className="Tabnames">
                  <ul>
                    <li
                      className={tabValue === "matches" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "matches")}
                    >
                      Matches
                    </li>
                    <li
                      className={tabValue === "myprofile" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "myprofile")}
                    >
                      My Profile
                    </li>

                    <li
                      className={tabValue === "chat" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "chat")}
                    >
                      My Chats
                    </li>

                    <li
                      className={tabValue === "requests" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "requests")}
                    >
                      Friend Requests
                    </li>
                    <li
                      className={tabValue === "contacts" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "contacts")}
                    >
                      Contact Requests
                    </li>
                    <li
                      className={tabValue === "favourite" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "favourite")}
                    >
                      Favourites
                    </li>
                    <li
                      className={tabValue === "subscription" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "subscription")}
                    >
                      My Subscription
                    </li>
                    {isMobile && (
                      <li
                        className={tabValue === "notifications" ? "active" : ""}
                        onClick={(e) => matchesNavigator(e, "notifications")}
                      >
                        Notifications
                      </li>
                    )}
                    <li
                      className={tabValue === "settings" ? "active" : ""}
                      onClick={(e) => matchesNavigator(e, "settings")}
                    >
                      Settings
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="Tabnames logoutTab">
                  <ul>
                    <li onClick={logoutHandler}> Log Out </li>
                  </ul>
                </div>
              </div>
            </div>
            {<Outlet />}
          </div>
        </>
      ) : (
        <Outlet />
      )}
      {!hideFooter() && <Footer />}
    </div>
  );
};

export default ProfileTabBar;
