import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TPermanentAndCurrentAddInfo,
  TRegisterInfo,
} from "../../../types/register";
import { useRegistrationState } from "./RegistrationProvider";
import icon from "./../../../assets/images/error.svg";
import PreviewImageModal from "./Modals/ImagePreviewModal";
import { RegistrainHeader } from "./RegistrationHeader";
import uploadico from "./../../../assets/images/uploadTicn.svg";
import { numberInputOnWheelPreventChange } from "../../../utils/helper";
import Loader from "../Loader";

export default function AddressForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { onHandleBack, onHandleNext, setRegistrationData, registrationData } =
    useRegistrationState();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<TRegisterInfo>({
    defaultValues: registrationData,
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const isSameAddress = watch("address.currentAddress.isSameAsPermanent");
  const address = watch("address.permanentAddress.address");
  const pincode = watch("address.permanentAddress.pincode");
  const city = watch("address.permanentAddress.city");
  const state = watch("address.permanentAddress.state");
  const aadharImage = watch("address.permanentAddress.aadhar");
  const [previewImage, setPreviewImage] = useState<File | null>(aadharImage);
  const { onChange, ...aadharParams } = register(
    "address.permanentAddress.aadhar",
  );

  function onHandleFormSubmit(data: TRegisterInfo) {
    setIsLoading(true);
    const addresssData: TPermanentAndCurrentAddInfo = {
      currentAddress: {
        ...data.address.currentAddress,
      },
      permanentAddress: {
        ...data.address.permanentAddress,
        aadhar: previewImage!,
      },
    };
    if (data.address.currentAddress.isSameAsPermanent) {
      addresssData.currentAddress.address =
        data.address.permanentAddress.address;
      addresssData.currentAddress.pincode =
        data.address.permanentAddress.pincode;
      addresssData.currentAddress.city = data.address.permanentAddress.city;
      addresssData.currentAddress.state = data.address.permanentAddress.state;
    }

    setRegistrationData((prevData) => ({
      ...prevData,
      address: addresssData,
    }));
    setIsLoading(false);
    onHandleNext();
  }

  function updateCurrentAddress() {
    setValue("address.currentAddress", {
      address,
      pincode,
      city,
      state,
      isSameAsPermanent: getValues("address.currentAddress.isSameAsPermanent"),
      friendName: getValues("address.currentAddress.friendName"),
      friendNumber: getValues("address.currentAddress.friendNumber"),
    });
    clearErrors("address.currentAddress");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSameAddress) {
      updateCurrentAddress();
    }
  }, [address, pincode, city, state, isSameAddress]);

  if (isLoading) return <Loader />;

  return (
    <div>
      {previewImage && isModalOpen && (
        <PreviewImageModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          images={previewImage && Array.from([previewImage!])}
        />
      )}

      <div className="addressForm addressForm2 ">
        <RegistrainHeader />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="progress-desktop" id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart"></i>
                </li>
                <li id="confirm">
                  <i className="fa fa-heart"></i>
                </li>
              </ul>
              <ul className="progress-mobile" style={{ display: "none" }} id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="payment">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="confirm">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="end">
                  <i className="fa fa-heart complete"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="detail-title">
              <div className="reg-icon">
                <img
                  src={require("../../../assets/images/location.png")}
                  alt="user"
                />
              </div>
              <div className="prsl-detail-heading">Permanent Address</div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div className="prsl-detail">
                  <form id="msform" onSubmit={handleSubmit(onHandleFormSubmit)}>
                    <fieldset>
                      <div className="form-card">
                        <div className="reg-forms">
                          <div className="upltxt_field">
                            <input
                              {...register("address.permanentAddress.address", {
                                required: "Required*",
                              })}
                              id="permanentaddress"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="House no/ Street no.*"
                              className="uploadinput"
                            />
                            {errors.address?.permanentAddress?.address && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {
                                  errors.address.permanentAddress.address
                                    .message
                                }
                              </div>
                            )}
                            <div className="dflex upldicn">
                              <input
                                type="file"
                                id="file-input"
                                accept="image/jpeg, image/png, image/jpg"
                                {...aadharParams}
                                className="custom-file-input"
                                onChange={(event) => {
                                  var file = event.target.files![0];
                                  if (file) {
                                    setPreviewImage(file);
                                    onChange(event); // calling onChange returned from register
                                  }
                                }}
                                required={previewImage ? false : true}
                              />
                              <label className="custom-file-label">
                                <img src={uploadico} width="30" />
                              </label>
                            </div>
                            {errors.address?.permanentAddress?.aadhar && (
                              <div>
                                {
                                  errors.address?.permanentAddress?.aadhar
                                    .message
                                }
                              </div>
                            )}

                            {previewImage && (
                              <div className="red">
                                <div className="error-msg dflex">
                                  <span>
                                    <button
                                      className="btn-trans"
                                      type="button"
                                      onClick={() => setIsModalOpen(true)}
                                    >
                                      View
                                    </button>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          {!previewImage && (
                            <div>
                              <div className="red">
                                <div className="error-msg dflex">
                                  <img src={icon} /> Upload Aadhar Card for
                                  verification
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="dflex space">
                          <div>
                            <input
                              {...register("address.permanentAddress.pincode", {
                                required: "Required*",
                                pattern: {
                                  value: /^[0-9]{6}$/,
                                  message: "Invalid pincode",
                                },
                              })}
                              id="permanentpincode"
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              placeholder="Pincode*"
                            />
                            {errors.address?.permanentAddress?.pincode && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {
                                  errors.address.permanentAddress.pincode
                                    .message
                                }
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              {...register("address.permanentAddress.city", {
                                required: "Required*",
                              })}
                              id="permanentcity"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="City*"
                            />
                            {errors.address?.permanentAddress?.city && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.address.permanentAddress.city.message}
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              {...register("address.permanentAddress.state", {
                                required: "Required*",
                              })}
                              id="permanentstate"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="State*"
                            />
                            {errors.address?.permanentAddress?.state && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.address.permanentAddress.state.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border"></div>
                      </div>

                      <div className="detail-titleTwo mln50">
                        <div className="reg-icon">
                          <img
                            src={require("../../../assets/images/location.png")}
                            alt="work"
                          />
                          <div className="prsl-detail-heading crnt">
                            Current Address
                          </div>
                        </div>

                        <div className="chkbox">
                          <input
                            {...register(
                              "address.currentAddress.isSameAsPermanent",
                            )}
                            id="samepermanentaddress"
                            type="checkbox"
                            placeholder="Same as permanent address"
                          />
                          <label>Same as permanent address</label>
                        </div>
                      </div>

                      <div className="reg-forms">
                        <div>
                          <input
                            {...register("address.currentAddress.address", {
                              required: "Required*",
                              disabled: isSameAddress,
                            })}
                            id="currentaddress"
                            type="text"
                            style={{ textTransform: "capitalize" }}
                            placeholder="House no/ street no.*"
                          />
                          {errors.address?.currentAddress?.address && (
                            <div className="error-msg">
                              <i className="fa fa-exclamation-triangle"></i>
                              {errors.address.currentAddress.address.message}
                            </div>
                          )}
                        </div>

                        <div className="dflex">
                          <div>
                            <input
                              {...register("address.currentAddress.pincode", {
                                disabled: isSameAddress,
                                required: "Required*",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Invalid pincode",
                                },
                              })}
                              id="currentpincode"
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              placeholder="Pincode*"
                            />
                            {errors.address?.currentAddress?.pincode && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.address.currentAddress.pincode.message}
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              {...register("address.currentAddress.city", {
                                required: "Required*",
                                disabled: isSameAddress,
                              })}
                              id="currentcity"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="City*"
                            />
                            {errors.address?.currentAddress?.city && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.address.currentAddress.city.message}
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              {...register("address.currentAddress.state", {
                                required: "Required*",
                                disabled: isSameAddress,
                              })}
                              id="currentstate"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="State*"
                            />
                            {errors.address?.currentAddress?.state && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.address.currentAddress.state.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="detail-titleTwo mln50">
                        <div className="reg-icon">
                          <img
                            src={require("../../../assets/images/success.png")}
                            alt="work"
                          />
                          <div className="prsl-detail-heading crnt">
                            Verification
                          </div>
                        </div>
                      </div>

                      <div className="reg-forms">
                        <div className="dflex">
                          <div>
                            <input
                              {...register(
                                "address.currentAddress.friendName",
                                {
                                  required: "Required*",
                                },
                              )}
                              id="friendname"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Friend Name*"
                            />
                            {errors.address?.currentAddress?.friendName && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {
                                  errors.address.currentAddress.friendName
                                    .message
                                }
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              {...register(
                                "address.currentAddress.friendNumber",
                                {
                                  required: "Required*",
                                  pattern: {
                                    value: /^[6-9]\d{9}$/,
                                    message: "Invalid number",
                                  },
                                },
                              )}
                              id="friendnumber"
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              placeholder="Friend Number*"
                            />
                            {errors.address?.currentAddress?.friendNumber && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {
                                  errors.address.currentAddress.friendNumber
                                    .message
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="reg-forms">
                        <div className="divider"></div>
                        <div className="btn-flex">
                          <div className="left-btn">
                            <button
                              className="btn-trans"
                              type="button"
                              onClick={onHandleBack}
                            >
                              <i className="fa-solid fa-arrow-left"></i> Back
                            </button>
                          </div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="cont-btn commonBtn"
                          >
                            Continue <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
