import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export function RegistrainHeader() {
  const navigate = useNavigate();
  return (
    <div className="registrainHeader">
      <Helmet>
        <title>Registration | Premmilan</title>
      </Helmet>
      <div className="container py25">
        <div className="row alignItemCenter">
          <div className="col-md-4">
            <div className="reg-logo">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/", { replace: false });
                }}
                src={require("../../../assets/images/Prem-Milan-Logo.png")}
                alt="logo"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="reg-centr-txt">
              <h1>Let’s Begin</h1>
              <p>Just few steps away to match and find your partner.</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="reg-right-txt">
              {/* <h2>
                Rishta
                <br />
                UmarBhar Ka
              </h2> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
