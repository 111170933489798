export const GENDERS = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "others", value: "Others" },
  // { id: "nonbinary", value: "Non-Binary" },
  // { id: "other", value: "Other" },
  // { id: "notanswer", value: "Prefer not to answer" },
];

export const MOTHER_TONGUE = [
  { id: "ass", value: "Assamese" },
  { id: "ben", value: "Bengali" },
  { id: "dog", value: "Dogri" },
  { id: "bod", value: "Bodo" },
  { id: "eng", value: "English" },
  { id: "guj", value: "Gujarati" },
  { id: "hin", value: "Hindi" },
  { id: "kan", value: "Kannada" },
  { id: "kas", value: "Kashmiri" },
  { id: "kon", value: "Konkani" },
  { id: "mai", value: "Maithili" },
  { id: "mal", value: "Malayalam" },
  { id: "mar", value: "Marathi" },
  { id: "mei", value: "Meitei" },
  { id: "nep", value: "Nepali" },
  { id: "odi", value: "Odia" },
  { id: "pun", value: "Punjabi" },
  { id: "sans", value: "Sanskrit" },
  { id: "san", value: "Santali" },
  { id: "sin", value: "Sindhi" },
  { id: "tam", value: "Tamil" },
  { id: "tel", value: "Telugu" },
  { id: "urd", value: "Urdu" },
  { id: "Marwari", value: "Marwari" },
  { id: "bhojpuri", value: "bhojpuri" },
  { id: "Rajasthani", value: "Rajasthani" },
  { id: "Garhwali", value: "Garhwali" },
  { id: "Harayanvi", value: "Harayanvi" },
  { id: "Tulu", value: "Tulu" },
];

export const RELIGIONS = [
  { id: "Hi", value: "Hindu" },
  { id: "sk", value: "Sikh" },
  { id: "ja", value: "Jain" },
  { id: "bu", value: "Buddhist" },
  { id: "ch", value: "Christian" },
  { id: "is", value: "Islam" },
];

export const COMMUNITIES = [
  { id: "Sunni", value: "Sunni" },
  { id: "Rajput", value: "Rajput" },
  { id: "Brahmin", value: "Brahmin" },
  { id: "Maratha", value: "Maratha" },
  { id: "Yadav", value: "Yadav" },
  { id: "Jat", value: "Jat" },
  { id: "Kayastha", value: "Kayastha" },
  { id: "Agarwal", value: "Agarwal" },
  { id: "Baniya", value: "Baniya" },
  { id: "Kshatriya", value: "Kshatriya" },
  { id: "Arora", value: "Arora" },
  { id: "Khatri", value: "Khatri" },
  { id: "Jatav", value: "Jatav" },
  { id: "Kashyap", value: "Kashyap" },
  { id: "Thakur", value: "Thakur" },
  { id: "Gupta", value: "Gupta" },
  { id: "Scheduled", value: "Scheduled Caste(SC)" },
  { id: "Kurmi", value: "Kurmi" },
  { id: "Lingayat", value: "Lingayat" },
  { id: "Teli", value: "Teli" },
  { id: "Shwetamber", value: "Shwetamber" },
  { id: "Vishwakarma", value: "Vishwakarma" },
  { id: "Kunbi", value: "Kunbi" },
  { id: "Sunnii", value: "Sunni Hanafi" },
  { id: "Vanniyar", value: "Vanniyar" },
  { id: "Kushwaha", value: "Kushwaha" },
  { id: "Gursikh", value: "Gursikh" },
  { id: "Reddy", value: "Reddy" },
  { id: "Digambar", value: "Digambar" },
  { id: "Patel", value: "Patel" },
  { id: "Adi", value: "Adi Dravida" },
  { id: "Catholic", value: "Catholic" },
];

export const MARITAL_STATUSES = [
  { id: "un", value: "Unmarried" },
  { id: "ma", value: "Married" },
  { id: "wi", value: "Widow" },
  { id: "di", value: "Divorced" },
];

export const PROFILE_MANAGED_BY = [
  { id: "myself", value: "Myself" },
  { id: "parent", value: "Parent" },
  { id: "sibiling", value: "Sibiling" },
  { id: "relative", value: "Relative" },
];

export const NATIONALITIES = [{ id: "in", value: "Indian" }];

export const FAMILYTYPES = [
  { id: "nuclear", value: "Nuclear" },
  { id: "joint", value: "Joint" },
];

export const Profiles = [
  { id: "1", value: "Self" },
  { id: "2", value: "Son" },
  { id: "2D", value: "Daughter" },
  { id: "3", value: "Sibling" },
  { id: "4", value: "Relative/Friend" },
  { id: "6D", value: "Sister" },
  { id: "6", value: "Brother" },
];

export const OCCUPATIONTYPES = [
  { id: "bus", value: "Business" },
  { id: "job", value: "Job" },
];

export const HIDE_PROFILE_DURATION = ["1 Week", "2 Week", "3 Week"];

export const REPORT_USER_TITLE = [
  "Fake Profile",
  "Rude or abusive behaviour",
  "Inappropriate content",
  "Scam or commercial",
  "Hate speech",
  "Off Bumble behaviour",
  "Underage",
  "I’m just not interested",
];
