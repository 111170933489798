import React, { useEffect, useState } from "react";
import { TSubscription } from "../subscription/Subscription";
import axiosClient from "../../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { daysToReadableFormat, randomString } from "../../utils/helper";
import Loader from "../accounts/Loader";

const PackageInfoBox = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { packageName } = useSelector((state: RootState) => state.package);
  const [exclusivePackage, setExclusivePackage] = useState<TSubscription>(
    {} as TSubscription
  );
  const [premiumPackage, setPremiumPackage] = useState<TSubscription>(
    {} as TSubscription
  );
  const client = axiosClient();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const res = await client.get("/package");
        if (true) {
          const userpkg = (res.data.result as TSubscription[]).filter(
            (x) => x.Type === "User"
          );
          const premium = userpkg?.find(
            (pkg) => pkg.Name.toLowerCase() === "premium"
          );
          const exclusive = userpkg?.find(
            (pkg) => pkg.Name.toLowerCase() === "exclusive"
          );
          setPremiumPackage(premium!);
          setExclusivePackage(exclusive!);
        }
      } catch (err: any) {
        console.log("error in suscription", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      {packageName === "" && (
        <div className="goExclusive">
          <div className="plansBx">
            <div className="planHaeading">
              <p> Go {premiumPackage.Name} </p>
            </div>
            <ul>
              <li>
                <div>
                  <img
                    className=""
                    src={require("../../assets/images/tick.png")}
                    alt="tickpng"
                  />
                </div>
                <p>
                  &nbsp;
                  <span>{daysToReadableFormat(premiumPackage?.Validity)}</span>
                </p>
              </li>
              {premiumPackage?.Description?.map((desc: any, key: number) => (
                <li key={randomString()}>
                  <div>
                    <img
                      className=""
                      src={
                        desc?.Status
                          ? require("../../assets/images/tick.png")
                          : require("../../assets/images/cross.png")
                      }
                      alt="tickpng"
                    />
                  </div>
                  <p>
                    <span>{desc?.Label} </span>
                  </p>
                </li>
              ))}

              <li>
                <div>
                  <img
                    className=""
                    src={require("../../assets/images/tick.png")}
                    alt="tickpng"
                  />
                </div>
                <p>
                  <span>
                    Send up to {premiumPackage.RequestPerDay} request/day
                  </span>
                </p>
              </li>
            </ul>

            <button
              onClick={(e) => {
                navigate("/subscription");
              }}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}
      {packageName.toLowerCase() === "premium" && (
        <div className="goExclusive">
          <div className="plansBx">
            <div className="planHaeading">
              <p> Go {exclusivePackage.Name} </p>
            </div>
            <ul>
              <li>
                <div>
                  <img
                    className=""
                    src={require("../../assets/images/tick.png")}
                    alt="tickpng"
                  />
                </div>
                <p>
                  <span>
                    &nbsp;
                    {daysToReadableFormat(exclusivePackage?.Validity)}
                  </span>
                </p>
              </li>
              {exclusivePackage?.Description?.map((desc: any, key: number) => (
                <li key={randomString()}>
                  <div>
                    <img
                      className=""
                      src={
                        desc?.Status
                          ? require("../../assets/images/tick.png")
                          : require("../../assets/images/cross.png")
                      }
                      alt="tickpng"
                    />
                  </div>
                  <p>
                    <span>{desc?.Label} </span>
                  </p>
                </li>
              ))}
              <li>
                <div>
                  <img
                    className=""
                    src={require("../../assets/images/tick.png")}
                    alt="tickpng"
                  />
                </div>
                <p>
                  <span>
                    Send up to {exclusivePackage.RequestPerDay} request/day
                  </span>
                </p>
              </li>
            </ul>

            <button
              onClick={(e) => {
                navigate("/subscription");
              }}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageInfoBox;
