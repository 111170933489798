import React, { useState, useRef, useCallback, Suspense, useEffect } from "react";
import Modal from "react-modal";
import type ReactPlayerType from "react-player";
import { isMobile } from "react-device-detect";

const ReactPlayer = React.lazy(() => import("react-player"));

type TVideoPreviewModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestDelete: () => void;
  videoBlob: Blob | null;
};

const VideoPreviewModal = ({
  isOpen,
  onRequestClose,
  onRequestDelete,
  videoBlob,
}: TVideoPreviewModalProps) => {
  const playerRef = useRef<ReactPlayerType | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [playIcon, setplayIcon] = useState<boolean>(false);
  const [pauseButton, setPauseButton] = useState<boolean>(false);

  const onReady = useCallback(() => {
    setPauseButton(!pauseButton);
    if (playerRef.current && !isReady) {
      const timeToStart = 10 * 60; // 10 minutes
      playerRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
      setplayIcon(true);
      setPauseButton(false)
    } else {
      setPauseButton(true)
    }

  }, [isReady]);

  if (!isOpen || !videoBlob) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Video Preview"
    >
      <div className="videoPerviewModal">
        <div className="videoRecorderBox videoRecorderFooter">
          <p className="prsl-detail-heading">Video Preview:</p>
          {
            <center>
              <div className="previewplay">
                { !isMobile &&
                  <i
                    className={`playicon fa fa-${pauseButton ? "pause" : "play"}`}
                    aria-hidden="true"
                    style={{ fontSize: 50, color: "white" }}
                  ></i>
                }
              </div>
              <Suspense fallback={<div>Loading...</div>}>
                <ReactPlayer
                  ref={playerRef}
                  onReady={onReady}
                  controls={true}
                  width="60%"
                  height="50%"
                  playsinline
                  url={URL.createObjectURL(videoBlob)}
                />
              </Suspense>
            </center>
          }
          <div className="btnbox">
            <button onClick={onRequestClose}>Close</button>
            <button onClick={onRequestDelete}>Delete</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoPreviewModal;
