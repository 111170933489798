import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Footer from "../Footer";
import BackArrow from "../../assets/images/cardArrow.svg";
import axiosClient from "../../services/axiosInstance";
import { TBlogDocs, TBlogs } from "./Blogs";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useEffect, useState } from "react";
import Loader from "../accounts/Loader";

export type TBlogDetail = TBlogDocs & {
  Content: string;
};

const BlogDetail = ({ params }: any) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const [blog, setBlog] = useState<TBlogDetail>({} as TBlogDetail);
  const client = axiosClient(token);

  useEffect(() => {
    async function fetchBlog() {
      try {
        setIsLoading(true);
        const res = await client.get(`/blog/${slug}`);
        if (true) {
          setBlog(res.data?.result);
        }
      } catch (err: any) {
        return "";
      } finally {
        setIsLoading(false);
      }
    }
    fetchBlog();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <Helmet>
        <title>{`${blog?.Title} | Premmilan`}</title>
      </Helmet>
      <section className="BlogDetailSection">
        <div className="BlogDetailMain">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blogDtlPgHdngBox">
                  <a onClick={() => navigate(-1)}>
                    <img src={BackArrow} width="30" />
                  </a>
                  <p className="headingPage">{blog?.Title}</p>
                  {/* <p className="blogDtlPgDate">
                    {blog?.CreatedAt &&
                      new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(blog?.CreatedAt))}
                  </p> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="blogDtlPara">{blog?.Content}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogDetail;
