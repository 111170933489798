import BackArrow from "../../../assets/images/cardArrow.svg";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import axiosClient from "../../../services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useEffect, useState } from "react";
import Loader from "../../accounts/Loader";
import { HIDE_PROFILE_DURATION } from "../../../utils/constants";
import MessageBoxModal from "./modal/MessageBoxModal";
import { Logout, setStatus } from "../../../state/features/loginSlice";
import { AxiosResponse } from "axios";
import { resetPackage } from "../../../state/features/packageSlice";
import { resetAuth } from "../../../state/features/authSlice";
import { resetFirebase } from "../../../state/features/firebaseSlice";
import { randomString } from "../../../utils/helper";

const HideDeleteProfile = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const dispatch = useDispatch();
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [days, setDays] = useState(7); // 1 Week
  const { id, status } = useSelector((state: RootState) => state.user);
  const [isProfileHidden, setIsProfileHidden] = useState(status === "HIDE");
  const [isProfileDeleted, setIsProfileDeleted] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(setStatus(isProfileHidden ? "HIDE" : "ACTIVE"));
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [isProfileHidden, status]);

  useEffect(() => {
    if (isProfileDeleted) {
      dispatch(Logout());
      dispatch(resetPackage());
      dispatch(resetAuth());
      dispatch(resetFirebase());
    }
  }, [isProfileDeleted]);

  const onHideUnHideProfile = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    try {
      setIsLoading(true);
      let res: AxiosResponse = {} as AxiosResponse;
      if (!isProfileHidden)
        res = await client.put(`/user/hide`, {
          UserId: id,
          Days: days,
        });
      else {
        res = await client.put(`/user/unhide`, {
          UserId: id,
        });
      }
      if (true) {
        setIsProfileHidden((prev) => !prev);
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDays(+e.target.value * 7);
  };

  const onDeleteProfile = async () => {
    if (isProfileDeleted) return;
    setIsLoading(true);
    try {
      const res = await client.delete(`/user/${id}`);
      if (true) {
        setShowModal(false);
        setIsProfileDeleted(true);
      }
    } catch (err: any) {
      console.log("error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="hidedeletepage">
      <div className="container matches-content">
        <div className="row">
          <div className="col-md-9">
            {showModal && (
              <MessageBoxModal
                message="Delete Profile ?"
                isOpen={showModal}
                handleOK={onDeleteProfile}
                onRequestClose={handleCancel}
              />
            )}
            <div>
              <div className="setting-hide-delete">
                <Helmet>
                  <title>Hide | Delete Profile | Premmilan</title>
                </Helmet>

                <div className="hideDeleteProfile">
                  <Link to="/profile/settings">
                    <img alt="BackArrow" src={BackArrow} width="30" />
                  </Link>
                  <div className="sign-in commanheading">
                    Hide / Delete Profile
                  </div>
                </div>

                <div className="div">
                  <div className="box1">
                    <div className="frame-3">
                      <div className="group-2 dflex justifyContentSpaceBetween alignItemCenter">
                        <div className="text-2">
                          <div className="smalltHeading">Hide Profile</div>
                          <p className="text">
                            You can view profiles but won’t be able to send
                            request <br /> or messages, and your profile will
                            not be shown to others.
                          </p>
                        </div>
                        <div className="button" onClick={onHideUnHideProfile}>
                          {isProfileHidden
                            ? "Unhide My Profile"
                            : "Hide My Profile"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dividerLine"></div>

                  <div className="box2">
                    <div className="frame-3">
                      <div className="group-3 dflex justifyContentSpaceBetween alignItemCenter">
                        <div className="text-2">
                          <p className="smalltHeading"> Delete Profile </p>
                          <p className="p">
                            This will permanently delete your profile along with{" "}
                            <br />
                            all matches and chat history.
                          </p>
                        </div>
                        <div
                          className="button"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          {isProfileDeleted
                            ? "Profile Deleted"
                            : "Delete My Profile"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HideDeleteProfile;
