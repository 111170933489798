import React, { useCallback, useRef } from "react";
import { Turnstile } from "@marsidev/react-turnstile";

type CaptchaProps = {
  setIsCaptchaSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const Captcha: React.FC<CaptchaProps> = ({ setIsCaptchaSuccess }) => {
  const captchaRef = useRef<any>(null);
  const handleSuccess = useCallback(() => {
    setIsCaptchaSuccess(true);
  }, [setIsCaptchaSuccess]);

  const handleError = useCallback(() => {
    if (captchaRef.current) captchaRef.current.reset();
    setIsCaptchaSuccess(false);
  }, [setIsCaptchaSuccess]);

  const handleExpire = () => {
    if (captchaRef.current) captchaRef.current.reset();
  };

  return (
    <Turnstile
      siteKey="0x4AAAAAAAhAp_BOzyTTzn8X"
      onSuccess={handleSuccess}
      ref={captchaRef}
      onError={handleError}
      onExpire={handleExpire}
    />
  );
};

export default Captcha;
