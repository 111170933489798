import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import addPhotos from "../../../../src/assets/images/SVGaddPhotos.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./style.css";
import { useEffect, useState } from "react";
import { TProfileData } from "../../../types/register";
import axiosClient from "../../../services/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { calculateAge } from "../../../utils/helper";
import ProfileBasicInfo from "./ProfileBasicInfo";
import ProfileCareerInfo from "./ProfileCareerInfo";
import ProfileFamilyInfo from "./ProfileFamilyInfo";
import ProfileAdressInfo from "./ProfileAdressInfo";
import ProfileAdditionalInfo from "./ProfileAdditionalInfo";
import ProfileAboutMe from "./ProfileAboutMe";
import ProfileContactInfo from "./ProfileContactInfo";
import ProfileEducationInfo from "./ProfileEducationInfo";
import ProfileDocumentInfo from "./ProfileDocumentInfo";
import UploadImageModal from "./modal/UploadImageModal";
import ShowImageModal from "./modal/ShowImageModal";
import Loader from "../../accounts/Loader";
import { useNavigate } from "react-router-dom";
import ShowPackageDot from "../components/ShowPackageDot";
import { TSubscription } from "../../subscription/Subscription";
import PackageInfoBox from "../../package/PackageInfoBox";

export type TPhotoType = {
  id: string;
  image: string;
};

const MyProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [photos, setPhotos] = useState([] as TPhotoType[]);
  const [currentPhoto, setCurrentPhoto] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [data, setData] = useState<TProfileData>({} as TProfileData);
  const { id } = useSelector((state: RootState) => state.user);
  const { packageName } = useSelector((state: RootState) => state.package);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.Photos) {
      setPhotos(
        data?.Photos?.map((photo) => {
          return {
            image: photo?.["50px"],
            id: photo._id,
          };
        }),
      );
    }
  }, [data && data?.Photos]);

  useEffect(() => {
    if (data && data.Photos)
      setCurrentPhoto(data?.Photos[currentImageIndex]?.["50px"]);
  }, [currentImageIndex, photos]);

  useEffect(() => {
    async function fetch() {
      if (isLoading) return;

      setIsLoading(true);
      try {
        const res = await client.get(`/user/${id}`);
        setData(res.data.result);
      } catch (err: any) {
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  const handleImageUpload = async (image: File) => {
    console.log("selected Image", image);
    const multipartClient = axiosClient(token, "Multipart");
    const formData = new FormData();
    formData.append("Image", image);
    try {
      const res = await multipartClient.post(`/user/photo/${id}`, formData);
      setPhotos((prev) => [
        ...prev,
        {
          id: res.data.result._id,
          image: res.data.result?.["50px"],
        },
      ]);
    } catch (err: any) { }
    setShowUploadModal(false);
  };

  const deleteImage = async (photoId: string) => {
    try {
      const res = await client.delete(`/user/photo/${id}`, {
        data: {
          PhotoId: photoId,
        },
      });
      const restPhotos = photos.filter((photo) => photo.id !== photoId);
      setPhotos(restPhotos);
    } catch (err: any) {
    } finally {
    }
  };

  const onShowPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const onShowNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1,
    );
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <Helmet>
        <title>My Profile | Premmilan</title>
      </Helmet>

      <UploadImageModal
        isOpen={showUploadModal}
        onRequestClose={() => setShowUploadModal(false)}
        onImageUpload={handleImageUpload}
      />

      <ShowImageModal
        isOpen={isPreviewModal}
        images={photos}
        onClose={() => setIsPreviewModal(false)}
        onDelete={deleteImage}
      />

      <section className="MyProfile">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="profileImgBox">
                <p className="tite">My Profile Details</p>
                <Swiper
                  className="mySwiper"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
                >
                  {photos &&
                    photos.map((photo, index) => (
                      <SwiperSlide key={index}>
                        <div className="profileImgInrBox">
                          <div className="ProfileOverlyDetail">
                            <div
                              className="contentNumber"
                              onClick={() => setIsPreviewModal(true)}
                            >
                              <span>
                                {photos?.length}{" "}
                                {photos?.length > 1 ? "Photos" : "Photo"}
                              </span>
                            </div>
                            <div className="dflex nameContent justifyContentBetween">
                              <div className="nameNAge">
                                <p className="userName">
                                  {data?.Personal?.FirstName}{" "}
                                  {data?.Personal?.LastName}{" "}
                                  {packageName && (
                                    <ShowPackageDot packageName={packageName} />
                                  )}
                                </p>
                                <p className="userAge">
                                  Age {calculateAge(data?.Personal?.Dob)}
                                </p>
                                <p> </p>
                              </div>

                              {photos?.length < 6 && (
                                <div className="addphotos">
                                  <div
                                    className="dflex"
                                    onClick={() => setShowUploadModal(true)}
                                  >
                                    <img src={addPhotos} alt="" /> Add Photos
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <img src={photo.image} alt="" />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <ProfileAboutMe data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <ProfileBasicInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <ProfileCareerInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <ProfileFamilyInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <ProfileAdressInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <ProfileAdditionalInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <ProfileContactInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <ProfileEducationInfo data={data} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <ProfileDocumentInfo data={data} />
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            {!isMobile && (
              <div className="col-md-4">
                <PackageInfoBox />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyProfile;
