import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import Timer from "./Modals/Timer";

type TVideoRecorderProps = {
  onStop: (blob: Blob) => void;
  onRequestClose: () => void;
};

const VideoRecorder = ({ onStop, onRequestClose }: TVideoRecorderProps) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [is20SecRecorded, setIs20SecRecorded] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (isRecording) {
      let timer = setTimeout(() => {
        setIs20SecRecorded(true);
      }, 10 * 1000);
      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const startRecording = () => {
    try {
      if (webcamRef.current) {
        setIs20SecRecorded(false);
        const stream = webcamRef.current.video!.srcObject as MediaStream;

        // Check browser support and select appropriate MIME type
        const mimeType = MediaRecorder.isTypeSupported(
          "video/webm;codecs=vp9,opus"
        )
          ? "video/webm;codecs=vp9,opus"
          : "video/mp4"; // Fallback for iOS Safari

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: mimeType,
        });

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            const blob = new Blob([event.data], { type: mimeType });
            onStop(blob);
          }
        };

        mediaRecorder.onstop = () => {
          setIsRecording(false);
        };

        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setIsRecording(true);
      }
    } catch (err) {
      setErrMsg((err as Error).toString());
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <div className="videoRecorderBox">
      <p className="prsl-detail-heading">Give Your 30 Seconds Introduction</p>
      <Webcam
        height="auto"
        width={700}
        audio={true}
        muted={true}
        ref={webcamRef}
        mirrored
        playsInline
      />

      <div className="videoRecorderFooter">
        <div className="videoinerbox">
          {/* <div>For Verification Purpose Only*</div> */}
          {!isRecording && <p>Record your 20 to 30 second introduction</p>}
          {!isRecording && (
            <>
              <button onClick={startRecording}>Start</button>
              <button onClick={onRequestClose}>Close</button>
              {/* <>{errMsg}</> */}
            </>
          )}
          {isRecording && <Timer seconds={29} onComplete={stopRecording} />}
          {is20SecRecorded && isRecording && (
            <button onClick={stopRecording}>Stop</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoRecorder;
