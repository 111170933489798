import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TRegisterInfo, TUserInterest } from "../../../types/register";
import { useRegistrationState } from "./RegistrationProvider";
import icon from "./../../../assets/images/error.svg";
import uploadico from "./../../../assets/images/uploadTicn.svg";
import ImagePreviewModal from "./Modals/ImagePreviewModal";
import VideoRecorderModal from "./Modals/VideoRecorderModal";
import VideoPreviewModal from "./Modals/VideoPreviewModal";
import { RegistrainHeader } from "./RegistrationHeader";
import {
  capitalizeFirstLetterofWord,
  capitalizeNestedJson,
  validatePhotoSize,
} from "../../../utils/helper";
import axiosClient from "../../../services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import Loader from "../Loader";
import { Link, useNavigate } from "react-router-dom";
import { setRegisterUserId } from "../../../state/features/registerSlice";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../../services/firebase";
import { doc, setDoc } from "firebase/firestore";
import TermsConditionModal from "./Modals/TermsConditionModal";

const AdditionalForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<string | null>();
  const [openTerm, setOpenTerm] = useState(false);
  const registerData = useSelector((state: RootState) => state.register);
  const [interest, setInterest] = useState<string>("");
  const [interestError, setInterestError] = useState("");
  const {
    setRegistrationData,
    registrationData,
    onHandleBack,
    interestList,
    recordedVideo,
    onSetRecordedVideo,
    onSetInterest,
    photos,
    onSetPhotos,
  } = useRegistrationState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<TRegisterInfo>({
    defaultValues: registrationData,
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isVideoPreviewModalOpen, setIsVideoPreviewModalOpen] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!(photos && photos.length)) setIsModalOpen(false);
  }, [photos]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onHandleFormSubmit = async (data: TRegisterInfo) => {
    const client = axiosClient(token, "Multipart");

    if (interestList === null || interestList.length < 5) {
      setInterestError("Add minimum 5 interest");
      return;
    }

    if (photos) {
      if (photos.length > 0 && photos.length < 3) {
        setErr("Upload minimum 3 Photos of You");
        return;
      }
      if (photos.length > 6) {
        setErr("Upload maximum 6 Photos of You");
        return;
      }
      if (validatePhotoSize(photos)) {
        setErr("Uploded file size must be between 100KB to 5MB");
        return;
      }
    }

    setRegistrationData((prevData) => ({
      ...prevData,
      ...data,
    }));

    setErr(null);

    try {
      setIsLoading(true);

      // register the user in firebase, catch block if already registered
      let firebaseRes;
      try {
        firebaseRes = await createUserWithEmailAndPassword(
          auth,
          registerData.email.toLowerCase(),
          registerData.password,
        );
      } catch (err) {
        firebaseRes = await signInWithEmailAndPassword(
          auth,
          registerData.email.toLowerCase(),
          registerData.password,
        );
      }

      const formData = new FormData();
      const { personal, educationWork, address, family } = registrationData;
      const { currentAddress, permanentAddress } = address;
      const { aadhar, ...restPermannent } = permanentAddress;
      const { ...restAdditional } = data.additional;

      formData.append("Aadhar", aadhar);

      for (let i = 0; i < photos.length; i++)
        formData.append("Photos", photos[i]);

      for (let i = 0; i < interestList.length; i++)
        formData.append("Interest", interestList[i].value);

      const videoFile = new File([recordedVideo], "video.mp4", { type: "video/mp4" });
      formData.append("Video", videoFile);
      formData.append("Email", registerData.email);
      formData.append("Password", registerData.password);
      formData.append("PartnerPreference", registerData.partnerPreference);
      formData.append("ProfileFor", registerData.profileFor);
      formData.append("ProfileManagedBy", registerData.profileManagedBy);

      const { firstName, lastName } = personal;
      const personainf = {
        firstName: capitalizeFirstLetterofWord(firstName),
        lastName: capitalizeFirstLetterofWord(lastName),
      };

      formData.append(
        "Personal",
        JSON.stringify(capitalizeNestedJson({ ...personainf, ...personal })),
      );

      formData.append(
        "EducationWork",
        JSON.stringify(capitalizeNestedJson({ ...educationWork })),
      );
      formData.append(
        "Family",
        JSON.stringify(capitalizeNestedJson({ ...family })),
      );
      formData.append(
        "CurrentAddress",
        JSON.stringify(capitalizeNestedJson({ ...currentAddress })),
      );
      formData.append(
        "PermanentAddress",
        JSON.stringify(capitalizeNestedJson({ ...restPermannent })),
      );
      formData.append(
        "Additional",
        JSON.stringify(capitalizeNestedJson({ ...restAdditional })),
      );
      formData.append("ChatUserId", firebaseRes.user.uid);

      const res = await client.post("/user", formData);
      if (true) {
        const firehoseData = {
          username: res.data.userId,
          email: registerData.email.toLowerCase(),
          id: firebaseRes.user.uid,
          blocked: [],
        };
        await setDoc(doc(db, "users", firebaseRes.user.uid), firehoseData);
        await setDoc(doc(db, "userchats", firebaseRes.user.uid), {
          chats: [],
        });

        setIsLoading(false);
        dispatch(setRegisterUserId(res.data.userId));
        navigate("/registrationsuccess", { replace: true });
        setTimeout(() => {
          navigate("/required-docs", {
            state: {
              address: permanentAddress.address,
              income: educationWork.work.exactIncome,
              occupation: educationWork.work.occupation,
            },
            replace: true,
          });
        }, 2500);
      }
    } catch (err: any) {
      console.log("failed", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onSetPhotos) onSetPhotos(fileListToArray(e.target.files!));
  };

  const videoRecordingStop = (blob: Blob) => {
    onSetRecordedVideo(blob);
  };

  const deleteRecordedVideo = () => {
    onSetRecordedVideo(new Blob());

    // setValue("additional.video", new Blob());
    setIsVideoPreviewModalOpen(false);
  };

  const openVideoModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setIsVideoModalOpen(true);
  };
  const closeModal = () => {
    setIsVideoModalOpen(false);
  };
  const removeImageByIndex = (index: number) => {
    const updatedPreviewImages = photos ? [...photos!] : [];
    updatedPreviewImages.splice(index, 1);
    onSetPhotos(updatedPreviewImages);
  };

  const onRemoveImage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    removeImageByIndex(index);
  };

  const removeInterest = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    const interests = [...interestList];
    interests.splice(index, 1);
    onSetInterest(interests);
  };

  function fileListToArray(fileList: FileList) {
    setErr(null);
    const filesArray = photos ? [...photos] : [];
    for (let i = 0; i < fileList.length; i++) {
      filesArray.push(fileList[i]);
    }
    if (validatePhotoSize(filesArray)) {
      setErr("Uploded file size must be between 100KB to 5MB");
      return [];
    }
    return filesArray;
  }

  const onRemoveAllImages = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onSetPhotos([]);
  };

  const onAddInterest = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setInterestError("");
    const inst: TUserInterest = { value: interest };
    onSetInterest([...interestList, inst]);
    setInterest("");
  };

  if (isLoading) return <Loader />;

  return (
    <div className="addtionalPage ">
      {photos && isModalOpen && (
        <ImagePreviewModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          images={photos}
          onRemove={onRemoveImage}
          onRemoveAll={onRemoveAllImages}
        />
      )}

      {isVideoModalOpen && (
        <VideoRecorderModal
          isOpen={isVideoModalOpen}
          onRequestClose={closeModal}
          onStop={videoRecordingStop}
        />
      )}

      {isVideoPreviewModalOpen && (
        <VideoPreviewModal
          isOpen={isVideoPreviewModalOpen}
          onRequestDelete={deleteRecordedVideo}
          onRequestClose={() => setIsVideoPreviewModalOpen(false)}
          videoBlob={recordedVideo}
        />
      )}

      {openTerm && (
        <TermsConditionModal
          isOpen={openTerm}
          onRequestClose={() => setOpenTerm(false)}
        />
      )}
      <div className="additional">
        <RegistrainHeader />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="progress-desktop" id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
              </ul>
              <ul className="progress-mobile" style={{ display: "none" }} id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="payment">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="confirm">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="end">
                  <i className="fa fa-heart complete"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3"> </div>
          <div className="col-md-6">
            <div className="detail-title">
              <div className="reg-icon">
                <img
                  src={require("../../../assets/images/additional.png")}
                  alt="user"
                />
              </div>
              <div className="prsl-detail-heading">Additional Information</div>
            </div>

            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div className="reg-form">
                  <div className="prsl-detail">
                    <form
                      id="msform"
                      onSubmit={handleSubmit(onHandleFormSubmit)}
                    >
                      <fieldset>
                        <div className="form-card">
                          <div>
                            <input
                              {...register("additional.nickName", {
                                required: "Required*",
                              })}
                              id="nickname"
                              style={{ textTransform: "capitalize" }}
                              type="text"
                              placeholder="Your nick name*"
                            />
                            {errors.additional?.nickName && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.additional?.nickName.message}
                              </div>
                            )}
                          </div>
                          <div className="Verify_link">
                            <input
                              value={interest}
                              onChange={(e) => setInterest(e.target.value)}
                              id="interest"
                              style={{ textTransform: "capitalize" }}
                              type="text"
                              placeholder="Your interest (Minimum 5)*"
                            />
                            {interest && interest.trim().length > 0 && (
                              <span>
                                <button
                                  className="btn-trans"
                                  type="button"
                                  onClick={onAddInterest}
                                >
                                  Add
                                </button>
                              </span>
                            )}
                            <div className="success-msg">
                              {interestList &&
                                interestList.map((interest, index) => (
                                  <div className="wrapper" key={index}>
                                    <p>{interest.value}</p>
                                    <span
                                      className="close"
                                      onClick={(e) => removeInterest(e, index)}
                                    />
                                  </div>
                                ))}
                            </div>
                            {interestError && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {interestError}
                              </div>
                            )}
                          </div>

                          <div>
                            <textarea
                              {...register("additional.expectation", {
                                required: "Required*",
                              })}
                              rows={5}
                              cols={60}
                              id="expectation"
                              placeholder="Write about your expectations from your partner*"
                            />
                          </div>
                          {errors.additional?.expectation && (
                            <div className="error-msg">
                              <i className="fa fa-exclamation-triangle"></i>
                              {errors.additional?.expectation.message}
                            </div>
                          )}
                          <div className="border"></div>
                          <p className="phototxt prsl-detail-heading">
                            Upload Your Photos & Video
                          </p>
                          <div className="upltxt_field">
                            <input
                              disabled
                              type="text"
                              placeholder="Upload upto 6 photos"
                              className="uploadinput"
                            />
                            <div className="dflex upldicn">
                              <input
                                onChange={handleAddImage}
                                type="file"
                                id="file-5"
                                className="custom-file-input"
                                accept="image/jpeg, image/png, image/jpg"
                                //onChange={handleImageChange}
                                multiple
                              />
                              <label className="custom-file-label">
                                <img src={uploadico} width="30" />
                              </label>
                            </div>

                            {photos && photos.length > 0 && (
                              <div className="viewBtnDiv">
                                <button
                                  className="btn-trans"
                                  type="button"
                                  onClick={() => {
                                    setIsModalOpen(true);
                                  }}
                                >
                                  View
                                </button>
                              </div>
                            )}
                          </div>

                          {photos?.length === 0 && (
                            <div className="red">
                              <div className="error-msg dflex">
                                <img src={icon} />
                                Upload minimum 3 Photos of You
                              </div>
                            </div>
                          )}
                          {err && (
                            <div>
                              <div className="red">
                                <div className="error-msg dflex">
                                  {err && <img src={icon} />}
                                  {err}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="left-btn-n-dscrptn space">
                          <div className="left-btn space">
                            {!recordedVideo?.size ? (
                              <div>
                                <span className="startLiveVideoBox">
                                  <button
                                    className="btn-trans"
                                    type="button"
                                    onClick={openVideoModal}
                                  >
                                    Start Live Video
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </button>
                                </span>
                                <p className="left-btn-dscrptn">
                                  Give your 30 seconds introduction
                                  <br />
                                  *For verification purpose only
                                </p>
                              </div>
                            ) : (
                              <span>
                                <button
                                  className="btn-trans"
                                  type="button"
                                  onClick={() =>
                                    setIsVideoPreviewModalOpen(true)
                                  }
                                >
                                  Preview Video
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </span>
                            )}
                          </div>
                        </div>
                        <p className="terms">
                          <input
                            type="checkbox"
                            name="terms"
                            className="custom-checkbox"
                          />
                          <span>
                            By clicking Submit, I agree that I have read and
                            accepted the{" "}
                            <span
                              className="termNcondition"
                              onClick={() => setOpenTerm(true)}
                            >
                              Terms & Conditions
                            </span>{" "}
                            and Privacy Policy.
                          </span>
                        </p>
                        <div className="divider"></div>
                        <div className="btn-flex">
                          <div className="left-btn">
                            <span>
                              <button
                                className="btn-trans"
                                type="button"
                                onClick={onHandleBack}
                              >
                                <i className="fa-solid fa-arrow-left"></i> Back
                              </button>
                            </span>
                          </div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="cont-btn commonBtn pdx40"
                          >
                            Submit
                          </button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3"> </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalForm;
