import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet-async";
import { Pagination, Navigation, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import Header from "../header/HeaderWithLogin";
import MainHeader from "../header/HeaderWithoutLogin";
import Footer from "../Footer";
import { RootState } from "../../state/store";
import axiosClient from "../../services/axiosInstance";
import UpgradeBtn from "../Upgrade_Btn";
import { useEffect, useState } from "react";
import Loader from "../accounts/Loader";

type TStory = {
  Name: string;
  Message: string;
  Image: string;
  CreatedAt: Date;
};

export default function App() {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [isLoading, setisLoading] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [stories, setStories] = useState<TStory[]>([] as TStory[]);

  useEffect(() => {
    async function fetchStories() {
      setisLoading(true);
      try {
        const res = await client.get("/story");
        if (true) {
          setStories(res.data.result);
        }
      } catch (err: any) {
        return [];
      } finally {
        setisLoading(false);
      }
    }
    fetchStories();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="happystoriePage">
      {isLoggedIn ? <Header /> : <MainHeader />}
      <div className="happy_stories">
        <div className="container">
          <Helmet>
            <title>Happy Stories | Premmilan</title>
          </Helmet>

          <div className="row">
            <div className="col-md-12">
              <div className="SubscriptionTopHeader py70">
                <p className="commanheading"> Happy Stories </p>
              </div>
            </div>
          </div>
        </div>

        <Swiper
          slidesPerView={1.5}
          slidesPerGroup={1}
          spaceBetween={30}
          keyboard={true}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            481: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            992: {
              slidesPerView: 1.5,
              spaceBetween: 40,
            },
            1400: {
              slidesPerView: 1.5,
              spaceBetween: 30,
            },
          }}
          // pagination={{
          //   clickable: true,
          // }}
          loop={true}
          // slidesPerView={'auto'}
          // centeredSlides={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation, Pagination, Keyboard]}
          className="mySwiper"
          // key={swiperKey}
        >
          {stories?.map((story, index) => (
            <SwiperSlide key={index}>
              <div className="profileBox">
                <div className="profile">
                  <div className="profileimg">
                    <img className="" src={story.Image} alt="imgtextbximg" />
                  </div>
                  <div className="contentBox">
                    <div>
                      <h2>{story.Name}</h2>
                      <p className="date">
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(story.CreatedAt))}
                      </p>
                      <div className="content">
                        <p>{story.Message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          {stories?.length > 0 && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="arrobxline">
                    <button className="swiper-button-prev btn-trans">
                      <img
                        className="swiper-button-prev"
                        src={require("../../assets/images/arrowleftStoriesIcon.png")}
                        alt="arrowlefttStoriesIcon"
                      />
                    </button>
                    <button className="swiper-button-next btn-trans">
                      <img
                        src={require("../../assets/images/arrowRightStoriesIcon.png")}
                        alt="arrowRightStoriesIcon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Swiper>

        <section className="ctabox">
          <div className="ctaboxmain">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="ctaboxtext">
                    <h5 className="commanheading fontColorWhite">
                      Go Exclusive to make your happy story faster
                    </h5>
                    <p>
                      Grant us the opportunity to better serve you in finding a
                      more suitable partner by upgrading to a membership.
                    </p>
                    <UpgradeBtn />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
