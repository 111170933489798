import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import MainHeader from "./header/HeaderWithoutLogin";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      event.preventDefault();
    };
    document.addEventListener("copy", handleCopy);
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  return (
    <div className="privacyPage no-select">
      <MainHeader />
      <Helmet>
        <title>Privacy Policy | Premmilan</title>
      </Helmet>
      <div className="privacy-policy">
        <div className="container">
          <h1>Privacy Policy</h1>
          <br />
          <div className="section">
            <h2>1. Information We Collect</h2>
            <p>
              <strong>1.1 Personal Information:</strong> When you register on
              our website, we collect personal information such as your name,
              email address, phone number, date of birth, gender, and any other
              details you choose to provide. This information helps us to match
              you with potential partners and enhance your experience on our
              platform.
            </p>
            <p>
              <strong>1.2 Profile Information:</strong> You may provide
              additional information to complete your profile, including
              photographs, educational background, employment details, and
              preferences related to your partner search.
            </p>
            <p>
              <strong>1.3 Usage Data:</strong> We collect data on how you use
              our website, including your IP address, browser type, operating
              system, and browsing activity. This information helps us improve
              our services and understand user behavior.
            </p>
            <p>
              <strong>1.4 Communication Data:</strong> We may collect
              information from communications you send to us, such as emails or
              support inquiries, to address your questions and improve our
              service.
            </p>
          </div>

          <div className="section">
            <h2>2. How We Use Your Information</h2>
            <p>
              <strong>2.1 To Provide and Improve Our Services:</strong> We use
              your personal and profile information to match you with potential
              partners, provide customer support, and improve the functionality
              of our website.
            </p>
            <p>
              <strong>2.2 To Communicate with You:</strong> We may use your
              contact information to send you updates, newsletters, and
              promotional materials related to our services. You can opt out of
              these communications at any time.
            </p>
            <p>
              <strong>2.3 For Research and Analysis:</strong> We may use
              aggregated and anonymized data to analyze trends, conduct
              research, and improve our website’s performance.
            </p>
          </div>

          <div className="section">
            <h2>3. How We Share Your Information</h2>
            <p>
              <strong>3.1 With Your Consent:</strong> We will share your
              personal information with other users only if you have explicitly
              consented to this by making your profile visible to others.
            </p>
            <p>
              <strong>3.2 With Service Providers:</strong> We may share your
              information with third-party service providers who assist us in
              operating our website and delivering our services. These providers
              are bound by confidentiality agreements and are only permitted to
              use your information for the purposes we specify.
            </p>
            <p>
              <strong>3.3 For Legal Compliance:</strong> We may disclose your
              information if required by law, to comply with legal processes, or
              to protect the rights, property, or safety of Premmilan, our users, or others.
            </p>
          </div>

          <div className="section">
            <h2>4. Data Security</h2>
            <p>
              We implement industry-standard security measures to protect your
              information from unauthorized access, use, or disclosure.
            </p>
          </div>

          <div className="section">
            <h2>5. Your Rights</h2>
            <p>
              <strong>5.1 Access and Correction:</strong> You have the right to
              access and update your personal information through your account
              settings. If you need assistance, please contact us.
            </p>
            <p>
              <strong>5.2 Data Deletion:</strong> You can request the deletion
              of your account and personal information by contacting our support
              team. Note that we may retain certain information as required by
              law or for legitimate business purposes.
            </p>
            <p>
              <strong>5.3 Opt-Out:</strong> You may opt out of receiving
              promotional communications from us by following the unsubscribe
              instructions provided in those communications.
            </p>
          </div>

          <div className="section">
            <h2>6. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to enhance your
              experience on our website. You can manage your cookie preferences
              through your browser settings.
            </p>
          </div>

          <div className="section">
            <h2>7. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page, and we will notify you of significant
              updates. Your continued use of our website after any changes
              indicates your acceptance of the revised policy.
            </p>
          </div>

          <div className="section">
            <h2>8. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </p>
            <p>
              <strong>Website:</strong> https://premmilan.com
              <br />
              <strong>Email:</strong> prem@premmilan.com
              <br />
              <span>
                <i
                  className="fab fa-whatsapp whatsapp-icon"
                  aria-hidden="true"
                ></i>
              </span>{" "}8989924249
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
