import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import BlockedCardCompo from "./BlockedCardCompo";
import { TGender } from "../../../types/base";
import BackArrow from "../../../assets/images/cardArrow.svg";

export type TUserBlocked = {
  _id: string;
  Username: string;
  FirstName: string;
  LastName: string;
  Photos: string;
  Video: string;
  CreatedAt: Date;
  isFavourite: boolean;
  DOB: Date;
  Gender: TGender;
  ExactIncome: number;
  City: string;
  State: string;
  EducationName: string;
  WorkTitle: string;
};

const Blocked = () => {
  const [favourites, setFavourites] = useState<TUserBlocked[]>([]);
  const { id } = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const fetchedDocumentIds = new Set();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);

  // UseEffects
  useEffect(() => {
    fetchFavourites();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchFavourites = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await client.get(`/user/block/${id}?page=${pageNumber}`);
      if (true) {
        const FavouritesData = res?.data?.result;
        const newFavourites = FavouritesData.filter(
          (x: TUserBlocked) => !fetchedDocumentIds.has(x._id),
        );
        setFavourites((prevItems) => [...prevItems, ...newFavourites]);
        FavouritesData.forEach((x: TUserBlocked) => {
          fetchedDocumentIds.add(x._id);
        });
        setPageNumber((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchFavourites();
  };

  if (isLoading)
    return (
      <>
        <center>
          <p>Loading matches...</p>
        </center>
      </>
    );

  return (
    <div className="blocked_profiles">
      <Helmet>
        <title>Blocked Profiles | Premmilan</title>
      </Helmet>
      <section className="MatchesPage">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blockedProfile">
                <Link to="/profile/settings">
                  <img alt="BackArrow" src={BackArrow} width="30" />
                </Link>
                <p className="headingPage">Blocked profiles</p>
              </div>
              {favourites &&
                favourites.map((favourite, index) => (
                  <BlockedCardCompo key={index} data={favourite} />
                ))}
              {isLoading && <p>Loading blocked profiles...</p>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Blocked;
